import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../util/firebaseconfig";

const Logout = () => {

  const navigate = useNavigate();

  signOut(auth).then(() => {
    console.log("Signed out the user.");
  }).catch((error) => {
    console.log("Error: ", error); 
    navigate("/home");
  });
  navigate("/home");

  // useEffect(() => {
  //   onAuthStateChanged(auth, function(user) {
  //       console.log("User: ", user); 
  //       if(user) {
  //           navigate("/allthreads"); 
  //       } 
  //       }); 
  //   }, []);

  return (
    <div className="container">
    </div>
  );
};

export default Logout;
