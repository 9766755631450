import React, { useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, storage, auth } from "../util/firebaseconfig";


const { collection, addDoc, getDocs } = require("firebase/firestore");
const { ref, uploadBytes, getDownloadURL } = require("firebase/storage");
const { onAuthStateChanged} = require("firebase/auth");


const Nav = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [user, setUser] = useState(null); 
  const [userId, setUserId] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsLoggedIn(true);
          setUserId(user.uid);
          } else {
          setIsLoggedIn(false);
          setUserId(null);
          navigate("/login");
        }
      });
  
      return () => unsubscribe();
    }, []);
  return (

<nav>
<div className="navbar navbar-expand-lg fixed-top bg-primary" data-bs-theme="dark">
    <div className="container-fluid">
    <a href="/home" className="navbar-brand">Memes With Coffee</a>
    <div className="navbar" id="navbarResponsive">
        <ul className="navbar-nav">
        { isLoggedIn && 
        <>          <li className="nav-item">
                <Link className ="nav-link" to="/allchats">Chats</Link>
          </li>
          <li className="nav-item">
                <Link className ="nav-link" to = "/logout"> Logout </Link> 
          </li>
          </>
        }
        {!isLoggedIn &&         
          <li className="nav-item">
                <Link className ="nav-link" to="/login">Login</Link>
          </li>
        }
        </ul>
    </div>
    </div>
</div>
</nav>
    // <nav>
    //   <ul className="nav-list">
    //     <li>
    //       <Link to="/login">Login</Link>
    //     </li>
    //     <li>
    //       <Link to="/allthreads">Threads</Link>
    //     </li>
    //     <li> 
    //       <Link to = "/logout"> Logout </Link> 
    //     </li>
    //     {/* <li>
    //       <Link to="/new-add">Post Ad</Link>
    //     </li> */}
    //   </ul>
    // </nav>
  );
};

export default Nav;





{/* <div className="container">
<div className="page-header" id="banner">
  <div className="row">
    <div className="col-lg-8 col-md-7 col-sm-6">
      <h1>News With Coffee</h1>
      <p className="lead">What do you want this website to be?</p>
      <p className="lead"> Catch up on news? </p>
      <p className="lead"> Play some games? </p>
      <p className="lead"> See some cool wallpapers? </p>
      <p className="lead"> Or read some research? </p>
    </div>
  </div>
</div>
</div> */}