// Home.js
import React, { useState, useEffect } from "react";
import { db } from "../util/firebaseconfig";
import { useNavigate, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
const { auth } = require("../util/firebaseconfig"); 
const { collection, getDocs, deleteDoc, doc, query, orderBy, addDoc, where, or, onSnapshot } = require("firebase/firestore");

const AllChats = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [user, setUser] = useState(null); 
  const [userId, setUserId] = useState(null); 
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [allRecipients, setAllRecipients] = useState([]);

  const [error, setError] = useState(null);
  const [chatFormData, setChatFormData] = useState({
    privateMessage: "",
  });

  const navigate = useNavigate();

  function handleChatInputChange(e) {
    setError(null);
    const {name, value} = e.target; 
    setChatFormData({...chatFormData, [name]: value});
  }

  async function handleSendChat(e) {
    e.preventDefault();
    try {
      const { privateMessage } = chatFormData; 
      const currentTime = Date.now();
      let isValid = true; 

      // Check that message is not all blank characters.
      const regex = /\S/;
      if (!regex.test(privateMessage)) {
        console.log("Regex test failed.")
        setError("Message cannot be blank.");
        isValid = false;
      }

      if (isValid) {       
        await addDoc(collection(db, "chats"), {
          message: privateMessage.trim(),
          messageTime: currentTime,
          senderId: userId,
          recipientId: selectedRecipient.recipientId,
          senderName: user.displayName, 
          recipientName: selectedRecipient.recipientName,
          authorName: user.displayName,
          authorId: userId,
        });

        // Reset form data, new images, and image previews
        setChatFormData({
          privateMessage: "",
        });
      } else {
        setChatFormData({
          privateMessage: "",
        });
      }
      
    } catch (error) {
      console.error("Error sending message: ", error.message); 
      setError(error.message);
    }
  }



  useEffect(() => {
    const fetchAllFriends = async () => {
        try {

            // 1. Fetch all associates.
            const friendsQuery = query(
                collection(db, "friends"),
                  where("userId", "==", userId)
                );
            const friendsSnapshot = await getDocs(friendsQuery);

            const recipients = [];
      
            for (const doc of friendsSnapshot.docs) {
              const friendsData = doc.data();
              const recipientId = friendsData.friendId;      
              recipients.push({ 
                id: recipientId, 
                recipientId: friendsData.friendId,
                recipientName: friendsData.friendName,
              });
              }
            console.log(recipients);
            setAllRecipients(recipients);                  
        } catch (error) {
            console.error("Error fetching associates:", error);
        }
    };

    fetchAllFriends();
}, [userId]);


  useEffect(() => {
      // Clear chatMessages when productId changes
      setChatMessages([]); 
      // Listen to any changes in the chats db.
      const messagesQuery = query(
          collection(db, "chats"),
          or(
            where("recipientId", "==", userId),
            where("senderId", "==", userId)),
          orderBy("messageTime")
      );
      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {    
            console.log("New chat message received.");
            // If a new chat message is received, update the state. 
            setChatMessages((prevChats) => [...prevChats, change.doc.data()]);
          }
        });
      });
      
      return () => unsubscribe();
    }, [selectedRecipient, userId]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(user);
          setIsLoggedIn(true);
          setUserId(user.uid);
          setUser(user);
        } else {
          console.log("No user found.");
          setIsLoggedIn(false);
          navigate("/login");
        }
      });

    return () => unsubscribe();
  }, []);

  const handleChatUserClick = (recipient) => {
    console.log("Selected user to chat with ", recipient);
    setSelectedRecipient(recipient);
  };

  function formatTime(milliSeconds) {
    var date = new Date(milliSeconds);
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    var formattedDate = date.toLocaleDateString("en", options); // 10/29/2013
    return formattedDate;
  }
  
  function truncateTo50Chars(message) {
    return message.substring(0, 80);
  }


  return (
          <div className="container mt-5">
          <div className="row">
              <div className="col-md-2 pb-4">
                  <h2>Your Chats</h2>
                  <ul className="shadow-sm list-group">
                      {allRecipients.map(recipient => (
                          <li 
                              key={recipient.recipientId} 
                              className={`list-group-item ${selectedRecipient && selectedRecipient.recipientId === recipient.recipientId ? 'active' : ''}`}
                              onClick={() => handleChatUserClick(recipient)}
                          >
                              <b>{recipient.recipientName}</b>
                          </li>
                      ))}
                  </ul>
              </div>
          {selectedRecipient && 
          <div className="col-md-8 m-3 p-3 border bg-light">
            <h5>Chatting with {selectedRecipient.recipientName} </h5>
            <hr></hr>
            <ul className="list-group shadow-sm">
                {chatMessages && chatMessages.map(message => (
                    <li className="list-group-item border"   key={message.id}>
                      <p>{message.message}</p>
                      <small className="text-body-secondary">Sent by: {message.authorName}</small>
                      <small className="text-body-secondary"> on {formatTime(message.messageTime)}</small>
                    </li>
                ))}
            </ul>
            <form onSubmit={handleSendChat}>
              <div className="form-group">
                <label htmlFor="privateMessage">Send a private message.</label>
                <input
                  className="form-control form-control-lg"
                  type="text"
                  id="privateMessage"
                  name="privateMessage"
                  value={chatFormData.privateMessage}
                  onChange={handleChatInputChange}
                />
              </div>
              {error && <p className="error-message">{error}</p>}
              {isLoggedIn ? (
                <button className="btn btn-primary" type="submit">
                  Reply
                </button>
              ) : (
                <button onClick={() => navigate("/login")}>Login</button>
              )}
            </form>
          </div>
        }
      </div>
      </div>
  );
};

export default AllChats;
