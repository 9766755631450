import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, onAuthStateChanged, browserSessionPersistence, browserLocalPersistence, setPersistence } from "firebase/auth";
import { auth } from "../util/firebaseconfig";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    
    setPersistence(auth, browserLocalPersistence).then(
        () => {

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/home");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
    });
    
  };

  useEffect(() => {

    onAuthStateChanged(auth, function(user) {
        console.log("User: ", user); 
        if(user) {
            navigate("/home"); 
        } 
        }); 
    }, []);

  return (
    <div className="container">
        <div className="col-lg-6">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label" htmlFor="username">Username (user user6@gmail.com to test): </label>
          <input
          className="form-control"
            type="text"
            id="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="password">Password (use user12 to test): </label>
          <input
          className="form-control"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className ="btn btn-primary w-100"> Login</button>
      </form>
      </div>
    </div>
  );
};

export default Login;
