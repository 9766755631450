// Home.js
import React, { useState, useEffect } from "react";
import { db, auth, storage } from "../util/firebaseconfig";
import { useNavigate, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
const { collection, getDocs, deleteDoc, doc, query, orderBy, limit, startAfter } = require("firebase/firestore");
const { getDownloadURL, ref, deleteObject } = require("firebase/storage");

function Home() {

  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [user, setUser] = useState(null); 
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);


  const navigate = useNavigate();

  const handleNewPostClick = () => {
    if (isLoggedIn) {
      navigate("/new-post");
    } else {
      navigate("/");
    }
  };
  const ITEMS_TO_FETCH_PER_PAGE = 5;

  async function fetchPosts() {
    try {
      setLoading(true);

      // Create a query to fetch posts ordered by postTime (descending)
      const postsRef = collection(db, "posts");
      const q = query(postsRef, orderBy("postTime", "desc"), limit(ITEMS_TO_FETCH_PER_PAGE)); // Fetch 10 posts initially

      const querySnapshot = await getDocs(q);
      const fetchedPosts = [];

      // Iterate through fetched documents and get image URLs
      for (const doc of querySnapshot.docs) {
        const postData = doc.data();
        const imageUrls = [];

        for (const imageUrl of postData.imageUrls) {
          const storageRef = ref(storage, imageUrl);
          const downloadUrl = await getDownloadURL(storageRef);
          imageUrls.push(downloadUrl);
        }

        fetchedPosts.push({ ...postData, id: doc.id, imageUrls }); // Include post ID
      }

      setPosts(fetchedPosts);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]); // Store the last document for pagination
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts: ", error);
      setError("Failed to fetch posts. Please try again later.");
      setLoading(false);
    }
  };
  
  // Function to fetch more posts (for pagination)
  const fetchMorePosts = async () => {
    if (loading || !lastDoc) return; // Prevent fetching while loading or if there are no more posts

    try {
      setLoading(true);

      const postsRef = collection(db, "posts");
      const q = query(postsRef, orderBy("postTime", "desc"), startAfter(lastDoc), limit(ITEMS_TO_FETCH_PER_PAGE));

      const querySnapshot = await getDocs(q);
      const fetchedPosts = [];

      for (const doc of querySnapshot.docs) {
        const postData = doc.data();
        const imageUrls = [];

        for (const imageUrl of postData.imageUrls) {
          const storageRef = ref(storage, imageUrl);
          const downloadUrl = await getDownloadURL(storageRef);
          imageUrls.push(downloadUrl);
        }

        fetchedPosts.push({ ...postData, id: doc.id, imageUrls }); // Include post ID
      }

      setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
      if (querySnapshot.docs.length > 0) {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setLastDoc(null);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching more posts: ", error);
      setError("Failed to fetch more posts. Please try again later.");
      setLoading(false);
    }
  };

  async function handleDeletePost(post) {
    try {
      const postId = post.id;

      // Assuming images are stored as an array of URLs or paths under `imageUrls`
      if (post.imageUrls && post.imageUrls.length > 0) {
        await Promise.all(
          post.imageUrls.map(async (imageUrl) => {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          })
        );
      }
        
      // Then delete the post itself.
      const productRef = doc(db, "posts", postId);
      await deleteDoc(productRef);

      // Update the local state to remove the product
      setPosts((prevPosts) =>
        prevPosts.filter((post) => post.id !== postId)
      );
    } catch (error) {
      console.error("Error deleting post and images: ", error.message);
    }
  }

  function formatTime(milliSeconds) {
    var date = new Date(milliSeconds);
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    var formattedDate = date.toLocaleDateString("en", options); // 10/29/2013
    return formattedDate;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUser(user);
        fetchPosts();
      } else {
        setIsLoggedIn(false);
        setUser(user);
      }
    });
  
    return () => unsubscribe();
  }, []);
      

    return (
      <div className = "container col-lg-8 mt-5">
      {isLoggedIn && 
        <button className="btn btn-primary btn-lg w-100" onClick={handleNewPostClick}>Make a new post</button>
      }

      {loading && <p>Loading posts...</p>}
      {error && <p className="error-message">{error}</p>}

      <ul className="list-unstyled"> {/* Use a list to display posts */}
        {posts.map((post) => (
          <li key={post.id} className="card border mb-2 mt-2 p-4 rounded shadow-sm">
            <div className="row">
              <div className="col-sm-7">
            <h3><strong>{post.title}</strong></h3>
            <p className="text-body-secondary">Author: {post.authorName}</p>
            <p className="text-body-secondary">Date Posted: {formatTime(post.postTime)}</p>
            <p>{post.description}</p>
            <Link
              to={`/post-detail/${post.id}`}
            > <button disabled = "true" className="btn btn-primary btn-sm">
                Reply to this post (disabled)
              </button>
            </Link>
            { isLoggedIn && post.userId === user.uid && 
            <button className="btn btn-secondary btn-sm" onClick={() => handleDeletePost(post)}>
              Delete</button> 
            }

            </div>
            <div className="col-sm-5">

            {post.imageUrls && (
            <div>
              <Link to={`/post-detail/${post.id}`}>
              {post.imageUrls.slice(0, 2).map((imageUrl, index) => (
                <img
                className="shadow-sm filled-img rounded mb-2"
                  key={index}
                  src={imageUrl}
                  alt={`Product ${index}`}
                />
              ))}
              </Link>
            </div>
            )}
            </div>
            </div>
          </li>
        ))}

      </ul>

      {/* Add a button to load more posts */}
      {!loading && lastDoc && (
        <button onClick={fetchMorePosts} className="btn btn-primary btn-lg w-100">Load More</button>
      )}
    </div>
    );
  }

export default Home; 

