import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/login"; // Import the Login component from src/components
import AllChats from "./components/allchats"; // Import the Home component from src/components
import Logout from "./components/logout"; 
import Home from "./components/home"; 
import NewPost from "./components/newpost";
import PostDetail from "./components/postdetail";
import Nav from "./router/Nav"; // Import the Nav component from src/components

const App = () => {
  return (
    <Router>
      <div>
        <Nav />
        <Routes>
        <Route path="/home" element={<Home />} /> {" "}
        <Route path="/" element={<Home />} /> {" "}
          <Route path="/login" element={<Login />} />{" "}
          <Route path="/allchats" element={<AllChats />} />{" "}
          <Route path="/logout" element = {<Logout />} /> {" "}
          <Route path="/new-post" element = {<NewPost />} /> {" "}
          <Route path="/post-detail/:postId" element = {<PostDetail />} /> {" "}
          {/* <Route path="/logout" element={<Logout />} />{" "} */}
          {/* <Route path="/new-add" element={<NewAd />} />{" "} */}
          {/* <Route path="/editProduct/:productId" element={<EditProduct />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
