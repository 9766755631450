// const {initializeApp} = require("firebase-admin/app");
// const {getFirestore} = require("firebase-admin/firestore");
// // const {onDocumentCreated} = require("firebase-functions/v2/firestore");
// initializeApp();

// const admin = require("firebase-admin");
// admin.initializeApp();

const {initializeApp} = require("firebase/app");
const {getFirestore, connectFirestoreEmulator} = require("firebase/firestore");
const {getAuth, connectAuthEmulator} = require("firebase/auth");
const { getStorage } = require("firebase/storage");

const firebaseConfig = {
  apiKey: "AIzaSyDx-9nEK2ZelkQQP65TpOMovRb-bC9CZos",
  authDomain: "storylist-android-app.firebaseapp.com",
  databaseURL: "https://storylist-android-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "storylist-android-app",
  storageBucket: "storylist-android-app.appspot.com",
  messagingSenderId: "648733631626",
  appId: "1:648733631626:web:0b81157ff2d214d795b973",
  measurementId: "G-9H9VEKW7BN"
};


const admin = initializeApp(firebaseConfig);
const db = getFirestore(admin);
const auth = getAuth(admin);
const storage = getStorage(admin);

// Remove these two lines when deploying to production
// connectAuthEmulator( getAuth(), "http://127.0.0.1:9099");
// connectFirestoreEmulator(db, "127.0.0.1", 8088);


module.exports = {admin, auth, db, storage};
