// PostDetail.js
import React, { useState, useEffect } from "react";
import { db, auth, storage } from "../util/firebaseconfig";
import { useParams, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
const { doc, getDoc, getDocs, collection, addDoc, serverTimestamp, query, orderBy } = require("firebase/firestore"); 
const { getDownloadURL, ref } = require("firebase/storage"); 

function PostDetail() {
  const { postId } = useParams(); 
  const [post, setPost] = useState(null);
  const [comment, setComment] = useState(""); 
  const [comments, setComments] = useState([]); 
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const fetchComments = async () => {
    try {
      const commentsRef = collection(db, "posts", postId, "comments");
      const q = query(commentsRef, orderBy("timestamp", "desc"));
      const querySnapshot = await getDocs(q);
      const fetchedComments = querySnapshot.docs.map((doc) => doc.data());
      setComments(fetchedComments);
    } catch (error) {
      console.error("Error fetching comments: ", error);
    }
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate("/home"); // Redirect to home if not logged in
      }
    });

    const fetchPost = async () => {
      try {
        const postDocRef = doc(db, "posts", postId);
        const postDocSnap = await getDoc(postDocRef);

        if (postDocSnap.exists()) {
          const postData = postDocSnap.data();
          const imageUrls = await Promise.all(
            postData.imageUrls.map(async (imageUrl) => {
              const storageRef = ref(storage, imageUrl);
              return await getDownloadURL(storageRef);
            })
          );
          setPost({ ...postData, imageUrls }); 
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching post details: ", error);
      }
    };

    fetchPost();
    fetchComments(); 

    return () => unsubscribeAuth(); 
  }, [postId, navigate]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return; 

    try {
      const commentsRef = collection(db, "posts", postId, "comments");
      await addDoc(commentsRef, {
        text: comment,
        userId: user.uid,
        userName: user.displayName || "Anonymous", 
        timestamp: serverTimestamp()
      });
      setComment(""); 
      fetchComments(); 
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  function formatTime(milliSeconds) {
    var date = new Date(milliSeconds);
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    var formattedDate = date.toLocaleDateString("en", options); // 10/29/2013
    return formattedDate;  
}

  if (!post) {
    return <div>Loading post details...</div>;
  }

  return (
    <div className="container col-lg-8 mt-5">
      <h2>{post.title}</h2>
      <p>Author: {post.authorName}</p>
      <p>Date Posted: {formatTime(post.postTime)}</p>
      <p>{post.description}</p>

      {post.imageUrls.map((imageUrl, index) => (
        <img 
          key={index}
          src={imageUrl} 
          alt={`Post Image ${index}`}
          className="img-fluid mb-3" 
        />
      ))}

      {/* Comments Section */}
      <h3>Comments</h3>
      {user && ( 
        <form onSubmit={handleCommentSubmit}>
          <div className="mb-3">
            <textarea
              className="form-control"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add your comment..."
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit Comment
          </button>
        </form>
      )}

      <ul className="list-unstyled mt-3">
        {comments.map((comment, index) => (
          <li key={index} className="card p-3 mb-2">
            <p>{comment.text}</p>
            <p className="text-muted">
              - {comment.userName} ({formatTime(comment.timestamp.toMillis())})
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PostDetail;